import gsap, { Quad } from 'gsap/all'
import { Text, Container } from 'pixi.js'

export default class MainLetter extends Container {
	constructor (letter) {
		super()
		this.letter = letter
		const options = {
			fontFamily: 'Arial',
			fontSize: 72,
			fill: 0xffffff,
			align: 'center'
		}
		this.text = new Text(letter, options)
		this.addChild(this.text)
		this.text.anchor.set(0.5, 0.5)
		gsap.to(this.text.scale, { y: 1.1, x: 1.1, ease: Quad.easeInOut, yoyo: true, duration: 2 + Math.random() * 0.1, repeat: -1 })
	}

	pop = () => {
		this.interactive = false
		const duration = 0.5
		gsap.to(this.text.scale, { x: 2.2, y: 2.2, ease: Quad.easeOut, duration: duration })
		gsap.to(this, { alpha: 0, duration: duration, onComplete: this.dispose })
	}

	dispose = () => {
		if (this.parent) {
			this.parent.removeChild(this)
			this.destroy()
		}
	}

	setText (text) {
		console.log(text)
		this.text.text = text
	}

	error = () => {
		console.log('nop')
	}
}
