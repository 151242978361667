import { Application } from 'pixi.js'

class Stage2d {
	constructor () {
		// var interactive = true
		// 0x000000, interactive
		this.app = new Application({
			width: window.innerWidth,
			height: window.innerHeight,
			antialias: true, // default: false
			backgroundColor: 0xFF0000
		})
		this.app.renderer.autoDensity = true
		this.app.renderer.view.style.position = 'absolute'
		this.app.renderer.view.style.display = 'block'
		this.app.renderer.view.style.top = 0
		this.app.renderer.view.style.left = 0
		this.app.renderer.view.style.zIndex = 999
		this.app.renderer.autoResize = true
		this.app.start()

		this.stage = this.app.stage
		this.init()
	}

	add (obj) {
		this.stage.addChild(obj)
	}

	remove (obj) {
		this.stage.removeChild(obj)
	}

	init = () => {
		document.body.appendChild(this.app.renderer.view)
	}

	dispose = () => {
		this.app.stop()
		document.body.removeChild(this.app.renderer.view)
	}
}

export default Stage2d
