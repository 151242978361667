import Stage2d from '@/mnf/2d/Stage2d'
import Letter from './Letter'
import MainLetter from './MainLetter'
import { Loader, Sprite } from 'pixi.js'
import { fit } from '@/mnf/2d/utils/image'
import Score from './Score'

export default class HangulRiver {
	constructor (level = 1) {
		this.stage2d = new Stage2d()
		fetch(`/data/games/hangul-river/level${level}.json`)
			.then((response) => response.json()).then((json) => {
				this.data = json
				this.map = {}
				for (const d of this.data) {
					this.map[d.kr] = d.fr
				}
				const loader = new Loader()
				loader.add('bg', 'img/games/hangul-river/main.jpg')
				this.sprites = {}
				loader.load((loader, resources) => {
					this.sprites.bg = new Sprite(resources.bg.texture)
					console.log(resources.bg.texture)
				})
				loader.onComplete.add(this.start)
			})
	}

	start = () => {
		this.createBG()
		this.stage2d.add(this.score = new Score())

		this.currentText = this.randomRoman()
		this.letters = []
		this.mainLetter = new MainLetter(this.currentText)
		this.mainLetter.position.x = window.innerWidth - 60
		this.mainLetter.position.y = window.innerHeight - 50
		this.stage2d.add(this.mainLetter)
		for (let i = 0; i < 6; i++) {
			this.createLetter(i)
		}
		this.updateCurrentText()
	}

	createBG () {
		const texture = this.sprites.bg.texture
		const dim = fit(texture.orig.width, texture.orig.height, window.innerWidth, window.innerHeight, false)
		const background = this.sprites.bg
		background.width = dim.width
		background.height = dim.height
		this.stage2d.add(background)
	}

	createLetter (id) {
		const letter = new Letter(this.randomKR())
		letter.id = id
		letter.on('click', this.onClickLetter)
		letter.on('tap', this.onClickLetter)
		this.stage2d.add(letter)
		const x = (window.innerWidth - 50) / 6 * id + 50
		letter.position.x = x + (Math.random() - 0.5) * 10
		letter.position.y = window.innerHeight * 0.7 + (Math.random() - 0.5) * 50 - id * 25
		this.letters.push(letter)
		return letter
	}

	randomKR () {
		return this.data[Math.floor(this.data.length * Math.random())].kr
	}

	randomRoman () {
		return this.data[Math.floor(this.data.length * Math.random())].fr
	}

	updateCurrentText () {
		this.currentText = this.map[this.letters[Math.floor(this.letters.length * Math.random())].letter]
		this.mainLetter.setText(this.currentText)
	}

	onClickLetter = (event) => {
		const letter = event.currentTarget
		if (this.map[letter.letter] == this.currentText) {
			this.score.addScore(150)
			const index = this.letters.indexOf(letter)
			if (index > -1) {
				this.letters.splice(index, 1)
			}
			letter.pop()

			let changeLetter = true
			for (const l of this.letters) {
				if (this.map[l.letter] == this.currentText) {
					changeLetter = false
				}
			}
			if (changeLetter) {
				this.updateCurrentText()
			}
			this.createLetter(letter.id)
		} else {
			this.score.addScore(-50)
			letter.error()
		}
	}

	dispose = () => {
		this.stage2d.dispose()
	}
}
