import gsap, { Quad } from 'gsap/all'
import { Container, Text } from 'pixi.js'

export default class Score extends Container {
	constructor () {
		super()
		this._score = 0
		const options = {
			fontFamily: 'Arial',
			fontSize: 26,
			fill: 0xffffff,
			align: 'center',
			letterSpacing: 5
		}
		this.text = new Text(this.format(0), options)
		this.text.anchor.set(0.5, 0.5)
		this.position.set(window.innerWidth / 2, 30)
		this.addChild(this.text)
	}

	addScore (value) {
		this.setScore(Math.max(0, this._score + value))
	}

	setScore (value) {
		value = Math.max(0, value)
		gsap.to(this, { _score: value, onUpdate: this.updateScoreText, duration: 0.2, ease: Quad.easeOut })
		gsap.to(this.text.scale, { y: 1.1, x: 1.1, yoyo: true, duration: 0.2, repeat: 1 })
	}

	updateScoreText = () => {
		this.text.text = this.format(this._score)
	}

	format (number) {
		const numberStr = String(parseInt(Math.floor(number))).padStart(6, '0')
		return numberStr
	}
}

export { Score }
