<template>
	<div class="Game" />
</template>

<script>

import HangulRiver from '@/games/hangulRiver/HangulRiver'

export default {
	name: 'About',
	mounted () {
		this.game = new HangulRiver()
	},
	methods: {
		transitionIn () {
		},
		transitionOut (cb) {
			this.game.dispose()
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut()
		next()
	}
}
</script>

<style lang="stylus" scoped>
</style>
