import gsap, { Quad } from 'gsap/all'
import { Text, Container } from 'pixi.js'

export default class Letter extends Container {
	constructor (letter) {
		super()
		this.letter = letter
		const options = {
			fontFamily: 'Arial',
			fontSize: 26,
			fill: 0xffffff,
			align: 'center'
		}
		this.text = new Text(letter, options)
		this.alpha = 0
		this.addChild(this.text)
		this.text.anchor.set(0.5, 0.5)
		const delayStart = Math.random() + 0.2
		this.interactive = true
		gsap.to(this, { alpha: 1, delay: delayStart })
		gsap.to(this.text.position, { y: 40 + Math.random() * 20, ease: Quad.easeInOut, yoyo: true, duration: 3 + Math.random(), delay: delayStart, repeat: -1 })
	}

	pop = () => {
		this.interactive = false
		const duration = 0.5
		gsap.to(this.text.scale, { x: 2.2, y: 2.2, ease: Quad.easeOut, duration: duration })
		gsap.to(this, { alpha: 0, duration: duration, onComplete: this.dispose })
	}

	dispose = () => {
		if (this.parent) {
			this.parent.removeChild(this)
			this.destroy()
		}
	}

	error = () => {
		console.log('nop')
	}
}
